import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NbToastrService, NbWindowService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { ArchivedHelperComponent } from '../../Utils/helper/archived-helper/archived-helper.component';

@Component({
  selector: 'ngx-archived-account-list',
  templateUrl: './archived-account-list.component.html',
  styleUrls: ['./archived-account-list.component.scss']
})
export class ArchivedAccountListComponent implements OnInit {

  constructor(
    private accountsService: AccountsService,
    private ws: NbWindowService,
    private toastrService: NbToastrService,
    private datePipe: DatePipe

  ) {}
  accountsData: Account[] = [];
  accounts: Account[];
  isLoading = true;
  accountId: string;
  account: Account;



  tabSettings = {
    hideSubHeader: false,

    actions: { edit: false, delete: false, add: false,
      custom: [
        {
          name: 'Restore',
          title: '<i class="fas fa-history fa-xs custom-restore-icon" title="Restore this account"></i>',
        }],
  
    },
  
    columns: {
      code: {
        title: 'Code',
        type: 'string',
      },
      name: {
        title: 'Name',
        type: 'string',
      },

      balance: {
        title: 'Balance',
        type: 'number',
      },
      createdAt: {
        title: 'Created At',
        valuePrepareFunction: (date) => {
          return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');
        },      },
      updatedAt: {
        title: 'Updated At',
        valuePrepareFunction: (date) => {
          return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm');
        },      },
     
    },
    pager: {
      display: true,
      perPage: 10,
    },
  };

  
  ngOnInit(): void {
   this.getAllArchivedAccounts()  }

  getAllArchivedAccounts(): void {
    this.accountsService.getArchivedAccounts().subscribe(
      data => {
        console.log('Received archived accounts :', data);
        this.accounts = data;
      },
      error => {
        console.error('Error fetching accounts:', error);
      }
    );
  }

  // Fonction pour confirmer la restauration du compte
  restoreConfirmation(accountId: string): void {
    if (window.confirm('Are you sure you want to restore this account?')) {
      this.restoreAccount(accountId);
    }
  }

  // Fonction pour restaurer le compte
  restoreAccount(accountId: string): void {
    this.accountsService.archiveAccount(accountId).subscribe({
      next: () => {
        this.showToast('success', 'Account Restored', 'The account has been restored successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 1500); 
      
      },
      error: (err: any) => {
        console.error('Error archiving account:', err);
        this.showToast('danger', 'Error', 'An error occurred while archiving the account.');
      }
    });
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }


  onCustom(event): void {
    if (event.action === 'Restore') {
      this.restoreConfirmation(event.data.id);
    }
  }

  openWindow() {
    this.ws.open(ArchivedHelperComponent, {title: `Help`});
  
  }
}