import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Account } from 'app/modules/financial-management/models/Account';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { AccountsService } from 'app/modules/financial-management/service/Accounts/accounts.service';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { th } from 'date-fns/locale';

@Component({
  selector: 'ngx-update-asset',
  templateUrl: './update-asset.component.html',
  styleUrls: ['./update-asset.component.scss']
})
export class UpdateAssetComponent implements OnInit {
  assetForm: FormGroup;
  accounts: Account[] = [];
  assetId: string;
  asset: Asset;
  formDisabled: boolean = true; 

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private assetService: AssetService,
    private accountService: AccountsService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute
  ) {
    this.assetForm = this.fb.group({
      name: [{ value: '', disabled: this.formDisabled }, Validators.required],
      initialValue: [{ value: 0, disabled: this.formDisabled }, Validators.required],
      currentValue: [{ value: '', disabled: this.formDisabled }, Validators.required],
      purchaseDate: [{ value: '', disabled: this.formDisabled }, Validators.required],
      account: [{ value: null, disabled: this.formDisabled }, Validators.required]
    });

    // Set currentValue to initialValue whenever initialValue changes
    this.assetForm.get('initialValue').valueChanges.subscribe(value => {
      this.assetForm.get('currentValue').setValue(value);
    });
  }

  ngOnInit(): void {
    this.assetId = this.route.snapshot.paramMap.get('id');
    this.getAssetDetails();
    this.loadAccounts();
  }

  loadAccounts(): void {
    this.accountService.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
      },
      (error) => {
        console.error('Error loading accounts:', error);
      }
    );
  }

  populateForm(): void {
    if (this.asset) {
      this.assetForm.patchValue({
        name: this.asset.name,
        initialValue: this.asset.initialValue,
        currentValue: this.asset.currentValue,
        purchaseDate: this.asset.purchaseDate,
        account: this.asset.account ? this.asset.account.id : null,
      });
    }
  }

  onSubmit(): void {
    if (this.assetForm.invalid) {
      console.error('Form is invalid');
      return;
    }

    const formValue = this.assetForm.value;
    const updatedAssetData = {
      ...formValue,
      account: this.accounts.find(account => account.id === formValue.account)
    };

    this.assetService.updateAsset(this.assetId, updatedAssetData).subscribe(
      updatedAj => {
        console.log('Asset updated successfully:', updatedAj);
        this.showToast('success', 'Success', 'Asset updated successfully');
        this.router.navigate(['/finance/assetsList']);
      },
      error => {
        console.error('Error updating asset:', error);
      }
    );
  }

  openDepreciationDialog(): void {
   {
   this.enableFormFields();
     const depreciationRate = prompt('Enter depreciation rate (e.g., 0.05 for 5%):');
      if (depreciationRate) {
        this.applyDepreciation(this.asset.id, parseFloat(depreciationRate));
      }
    }
  }

  showWarning() {
  }
  
  applyDepreciation(id: string, depreciationRate: number): void {
    this.assetService.applyDepreciation(id, depreciationRate).subscribe(
      (updatedAsset: Asset) => {
        this.asset = updatedAsset;
        this.populateForm();
        this.toastrService.success('Depreciation applied successfully', 'Success');
      },
      (err) => {
        console.error('Error applying depreciation:', err);
        this.toastrService.danger('Error applying depreciation', 'Error');
      }
    );
  }

  getAssetDetails(): void {
    this.assetService.getAssetById(this.assetId).subscribe(
      asset => {
        this.asset = asset;
        this.populateForm();
      },
      error => {
        console.error('Error fetching asset details:', error);
      }
    );
  }

  archiveConfirmation(): void {
    if (window.confirm('Are you sure you want to archive this asset?')) {
      this.archiveAsset();
    }
  }

  archiveAsset(): void {
    if (this.asset && this.asset.id) {
      this.assetService.archiveAsset(this.asset.id).subscribe({
        next: () => {
          this.toastrService.success('Asset archived successfully', 'Success');
          this.router.navigate(['/finance/assetsList']);
        },
        error: (err: any) => {
          console.error('Error archiving asset:', err);
          this.toastrService.danger('Failed to archive asset', 'Error');
        }
      });
    } else {
      console.error('Asset id is undefined.');
      this.toastrService.danger('Failed to archive asset', 'Error');
    }
  }

  showToast(status: string, title: string, message: string) {
    this.toastrService.show(message, title, { status });
  }

  // Méthode pour activer tous les champs du formulaire
  enableFormFields(): void {
    this.formDisabled = false;
    Object.keys(this.assetForm.controls).forEach(key => {
      this.assetForm.controls[key].enable();
    });
  }
}
