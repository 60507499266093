<mat-card>
    <mat-card-title></mat-card-title>
    <nb-card-header>
        <div class="text-center">
            <h1>Financial Report of {{year}}
            </h1>
        </div>
        <div class="row align-items-center justify-content-end">
            <div class="col-auto">
                <button class="mx-2" (click)="generatePDF()" nbTooltip="Export to pdf file" nbTooltipStatus="primary" nbButton status="danger">
            <i class="fas fa-file-pdf text-white"></i>
          </button> &nbsp;
                <button nbButton (click)="exportExcel()" nbTooltip="Export to Excel" nbTooltipStatus="primary" status="success">
            <i class="fas fa-file-excel text-white"></i>
          </button>
            </div>
        </div>
    </nb-card-header>
</mat-card>

<div id="reportContent">
    <br><br>
    <ngx-balance-sheet></ngx-balance-sheet>
    <ngx-icome-statement-list></ngx-icome-statement-list>
</div>