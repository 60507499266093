import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DailyCashflowComponent } from './components/Transactions/annual-cashflow/daily-cashflow.component';

import {TransactionsHistoryComponent} from './components/Transactions/transactions-history/transactions-history.component';
import {SalesProcessComponent} from './components/Sales/sales-process/sales-process.component';
import {AllMonthsComponent} from './components/Transactions/annual-cashflow/months/all-months/all-months.component';
import {SecondStepComponent} from './components/Sales/sales-process/steps/second-step/second-step.component';
import { AnnualBudgetComponent } from './components/Budgets/annual-budget/annual-budget/annual-budget.component';
import {MonthlyBudgetComponent} from './components/Budgets/monthly-budget/monthly-budget/monthly-budget.component';
import {ForecastBudgetComponent} from './components/Budgets/forecast-budget/forecast-budget.component';
import {PurchaseRequestsComponent} from './components/Purchases/purchase-requests/purchase-requests.component';
import {PurchaseOrdersComponent} from './components/Purchases/purchase-orders/purchase-orders.component';
import {ReconciliationComponent} from './components/Reconciliation/reconciliation/reconciliation.component';
import {ProductsSalesProcessComponent,
} from './components/Sales/products-sales-process/products-sales-process.component';
import {IncomeStatementComponent} from './components/Income Statement/income-statement/income-statement.component';
import {CashFlowRiskComponent} from './components/Risk/cash-flow-risk/cash-flow-risk.component';
import {SalesRiskComponent} from './components/Risk/sales-risk/sales-risk.component';
import {FinanceInsightsComponent} from './components/finance-insights/finance-insights.component';
import { TaxesManagementComponent } from './components/Transactions/Taxes/taxes-management/taxes-management.component';
import { DividendPrcocessManagementComponent } from './components/Dividend/dividend-prcocess-management/dividend-prcocess-management.component';
import { AccountListComponent } from './components/Account/account-list/account-list.component';
import { UpdateAccountComponent } from './components/Account/update-account/update-account.component';
import { ArchivedAccountListComponent } from './components/Account/archived-account-list/archived-account-list.component';
import { DividendDetailsComponent } from './components/Dividend/dividend-details/dividend-details.component';
import { SharehlderDetailsComponent } from './components/Dividend/sharehlder-details/sharehlder-details.component';
import { ArchivedDividendComponent } from './components/Dividend/archived-dividend/archived-dividend.component';
import { ArchivedShareholdersComponent } from './components/Dividend/archived-shareholders/archived-shareholders.component';
import { UpdateTaxeComponent } from './components/Transactions/Taxes/taxes-management/update-taxe/update-taxe.component';
import { ArchivedTaxesListComponent } from './components/Transactions/Taxes/taxes-management/archived-taxes-list/archived-taxes-list.component';
import { PeriodicBudgetManagementComponent } from './components/Budgets/PeriodicBudget/periodic-budget-management/periodic-budget-management.component';
import { ProvisionDataListComponent } from './components/Provision/provision-data-list/provision-data-list.component';
import { AddProvisionComponent } from './components/Provision/add-provision/add-provision.component';
import { UpdateProvisionComponent } from './components/Provision/update-provision/update-provision.component';
import { ArchivedProvisionListComponent } from './components/Provision/archived-provision-list/archived-provision-list.component';
import { ProvisionListComponent } from './components/Provision/provision-list/provision-list.component';
import { FinancialReportListComponent } from './components/FinancialReporting/financial-report-list/financial-report-list.component';
import { AddLiabilityComponent } from './components/Liability/add-liability/add-liability.component';
import { LiabilityListComponent } from './components/Liability/liability-list/liability-list.component';
import { UpdateLiabilityComponent } from './components/Liability/update-liability/update-liability.component';
import { ArchivedLiabilityListComponent } from './components/Liability/archived-liability-list/archived-liability-list.component';
import { AddAssetComponent } from './components/Assets/add-asset/add-asset.component';
import { UpdateAssetComponent } from './components/Assets/update-asset/update-asset.component';
import { AssetsListComponent } from './components/Assets/assets-list/assets-list.component';
import { ArchivedAssetsListComponent } from './components/Assets/archived-assets-list/archived-assets-list.component';
import { AddExpenseComponent } from './components/Expenses/add-expense/add-expense.component';
import { UpdateExpenseComponent } from './components/Expenses/update-expense/update-expense.component';
import { ExpenseslistComponent } from './components/Expenses/expenseslist/expenseslist.component';
import { ArchivedExpensesListComponent } from './components/Expenses/archived-expenses-list/archived-expenses-list.component';
import { ExpensesDataListComponent } from './components/Expenses/expenses-data-list/expenses-data-list.component';
import { LiabiliyDataListComponent } from './components/Liability/liabiliy-data-list/liabiliy-data-list.component';
import { AssetsDataListComponent } from './components/Assets/assets-data-list/assets-data-list.component';
import { AddAccountComponent } from './components/Account/add-account/add-account.component';
import { AddTaxComponent } from './components/Transactions/Taxes/taxes-management/add-tax/add-tax.component';
import { TaxDataListComponent } from './components/Transactions/Taxes/taxes-management/tax-data-list/tax-data-list.component';
import { AccountDataListComponent } from './components/Account/account-data-list/account-data-list.component';
import { LiabilitiesReconciliationComponent } from './components/Reconciliation/liabilities-reconciliation/liabilities-reconciliation.component';
import { MoneyFlowComponent } from './components/money-flow/money-flow.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';



const routes: Routes = [

  {path : 'annualCashFlow', component: DailyCashflowComponent},
  {path : 'annualCashFlow/months/:month/:year', component: AllMonthsComponent},
  {path : 'history', component: TransactionsHistoryComponent},
  {path: 'salesProcess', component: SalesProcessComponent},
  {path: 'productsSalesProcess', component: ProductsSalesProcessComponent},

  {path: 'annualBudget', component: AnnualBudgetComponent},
  {path: 'monthlyBudget/:month/:year', component: MonthlyBudgetComponent},
  {path: 'forecastBudget/:year', component: ForecastBudgetComponent},
  {path : 'salesProcess/secondStep' , component: SecondStepComponent},
  {path : 'purchaseRequests', component: PurchaseRequestsComponent},
  {path : 'purchaseOrders', component: PurchaseOrdersComponent},
  {path : 'reconciliation', component: ReconciliationComponent},
  {path : 'incomeStatement', component: IncomeStatementComponent},
  {path : 'cfRisk', component: CashFlowRiskComponent},
  {path : 'salesRisk', component: SalesRiskComponent},
  {path : 'financeInsights', component: FinanceInsightsComponent},
  {path : 'taxes', component: TaxesManagementComponent},
  {path : 'addTax', component:AddTaxComponent},
  {path : 'taxDataList', component:TaxDataListComponent},



  {path : 'periodicBudget', component: PeriodicBudgetManagementComponent},
  {path: 'dividentProcess', component: DividendPrcocessManagementComponent},
  {path: 'accountManagement', component: AccountListComponent},
  {path: 'accountDataList', component: AccountDataListComponent},

  {path: 'addAccount', component: AddAccountComponent},
  { path: 'update-account/:id', component: UpdateAccountComponent },
  { path: 'archivedAccounts', component: ArchivedAccountListComponent },
  { path: 'dividendDetails/:id', component: DividendDetailsComponent },
  { path: 'sharehlderDetails/:id', component: SharehlderDetailsComponent },
  { path: 'archivedDividends', component: ArchivedDividendComponent },
  { path: 'archivedShareholders', component: ArchivedShareholdersComponent },
  { path: 'updateTax/:id', component: UpdateTaxeComponent },
  { path: 'archivedTaxes', component: ArchivedTaxesListComponent },
  { path: 'provsionDataList', component: ProvisionDataListComponent },
  { path: 'provsionList', component: ProvisionListComponent },
  { path: 'addProvison', component: AddProvisionComponent },
  { path: 'updateProvision/:id', component: UpdateProvisionComponent },
  { path: 'archivedProvisionList', component: ArchivedProvisionListComponent },
  { path: 'financialReportList', component: FinancialReportListComponent },
  { path: 'addLiability', component: AddLiabilityComponent },
  { path: 'liabilityList', component: LiabilityListComponent },
  { path: 'liabilityDataList', component:LiabiliyDataListComponent },
  { path: 'updateLiability/:id', component: UpdateLiabilityComponent },
  { path: 'archivedLiabilityList', component: ArchivedLiabilityListComponent },

  { path: 'addAsset', component: AddAssetComponent },
  { path: 'updateAsset/:id', component: UpdateAssetComponent },
  { path: 'assetsList', component: AssetsListComponent },
  { path: 'archivedAssetsList', component: ArchivedAssetsListComponent },
  { path: 'assetsDataList', component:AssetsDataListComponent },



  
  { path: 'addExpense', component: AddExpenseComponent },
  { path: 'updateExpense/:id', component: UpdateExpenseComponent },
  { path: 'expensesList', component: ExpenseslistComponent },
  { path: 'expensesDataList', component: ExpensesDataListComponent },
  { path: 'archivedExpenseList', component: ArchivedExpensesListComponent },


  { path: 'reconciliedLiabilities', component: LiabilitiesReconciliationComponent },
  { path: 'gap', component: MoneyFlowComponent },
  { path: 'chatbot', component: ChatbotComponent },


























];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinancialManagementRoutingModule {

}