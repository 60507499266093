import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs/dist/exceljs.min';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { LiabilityService } from 'app/modules/financial-management/service/LiabilityService/liability-service.service';
import { TransactionService } from 'app/modules/financial-management/service/Transaction/transaction.service';

@Component({
  selector: 'ngx-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styleUrls: ['./financial-report-list.component.scss']
})
export class FinancialReportListComponent implements OnInit {
  assetsData: any[] = [];
  liabilityData: any[] = [];
  transactionData: any[] = [];
  year: number = new Date().getFullYear();


  constructor(
    private assetService: AssetService,
    private liabilityService: LiabilityService,
    private transactionService: TransactionService
  ) {}

  ngOnInit(): void {
    this.getAllAssets();
    this.getAllLiabilities();
    this.getAllTransactions();

  }

  getAllAssets() {
    this.assetService.getAllAssets().subscribe(
      (data: any[]) => {
        this.assetsData = data.filter(l => !l.archived);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getAllLiabilities() {
    this.liabilityService.getAllLiabilities().subscribe(
      (data: any[]) => {
        this.liabilityData = data.filter(l => !l.archived);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getAllTransactions() {
    this.transactionService.getAllTransactions().subscribe(
      (data: any[]) => {
        this.transactionData = data;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  generatePDF() {
    const content = document.getElementById('reportContent');
    const isDarkMode = document.body.classList.contains('dark-theme');
  
    if (content) {
      setTimeout(() => {
        html2canvas(content, {
          backgroundColor: isDarkMode ? '#333333' : '#ffffff'
        }).then(canvas => {
          const imgWidth = 208;
          const pageHeight = 295;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
  
          const doc = new jsPDF('p', 'mm', 'a4');
          let position = 20;
  
          // Ajout d'un titre avec une police plus grande et en gras
          doc.setFontSize(24);
          doc.setFont('helvetica', 'bold');
          doc.text('Financial Report', 10, 20);
  
          // Ajout du contenu du canvas
          const imageData = canvas.toDataURL('image/png');
          doc.addImage(imageData, 'PNG', 10, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight + 20;
            doc.addPage();
            doc.addImage(imageData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
  
          doc.save('Financial Report.pdf');
          const trace = {
            fileName: 'Financial Report.pdf',
            contentType: 'application/pdf',
            module: 'Finance',
            createdAt: new Date().toISOString()
          };
  
          
        });
      }, 100);
    }
  }

  exportExcel(): void {
    const workbook = new Workbook();

  // Feuille pour les transactions
  if (this.transactionData && this.transactionData.length > 0) {
    const transactionsSheet = workbook.addWorksheet('Incomes');
    this.addTableToSheet(transactionsSheet, 'Incomes', ['Details', 'Date', 'Type','Income','Expense', 'Balance'], 
      this.transactionData.map(tr => [tr.details, tr.date, tr.type,tr.income,tr.expense,tr.balance]));
  }

  // Feuille pour les assets
  if (this.assetsData && this.assetsData.length > 0) {
    const assetsSheet = workbook.addWorksheet('Assets');
    this.addTableToSheet(assetsSheet, 'Assets', [ 'Initial Value', 'Current Value','Purchase Date','Depreciation Date','Depreciation Rate'],
       this.assetsData.map(as => [as.initialValue ,as.currentValue,as.purchaseDate,as.depreciationDate,as.depreciationRate]));
  }
 
  // Feuille pour les Liability
  if (this.liabilityData && this.liabilityData.length > 0) {
    const liabilitiesSheet = workbook.addWorksheet('Liabilities');
    this.addTableToSheet(liabilitiesSheet, 'Liabilities', ['Type', 'Amount Owed','Interest Rate','Maturity Date',
    'Repayment Terms','Collateral','Covenants','Purpose','Credit Rating','Legal Requirements'], 
    this.liabilityData.map(l => [l.type,l.amountOwed,l.interestRate,l.maturityDate,l.repaymentTerms,l.collateral,l.covenants,l.purpose,l.creditRating,l.legalRequirements]));
  }



  
    // Génération du fichier Excel
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'Financial Report.xlsx');
    });
  }
  
  addTableToSheet(sheet: any, title: string, headers: string[], data: any[][]) {
    sheet.mergeCells('A1', 'C1');
    const titleRow = sheet.getCell('A1');
    titleRow.value = title;
    titleRow.font = { name: 'Calibre', size: 25, bold: true, color: { argb: '4167B8' } };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
  
    // Ajouter les en-têtes de colonnes
    const headerRow = sheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA500' } };
      cell.font = { bold: true, color: { argb: 'FFFFFF' }, size: 12 };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
  
    // Ajouter les données du tableau
    data.forEach(rowData => {
      const row = sheet.addRow(rowData);
      row.eachCell(cell => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });
  
    // Ajuster les largeurs des colonnes
    sheet.columns.forEach(column => {
      column.width = Math.max(10, column.values.reduce((max, val) => Math.max(max, String(val).length), 0));
    });
  }
  
  


}
