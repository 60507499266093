import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTypeComponent } from '../../Transactions/annual-cashflow/months/Utils/Editors/date-type/date-type.component';
import { TypeTypeComponent } from '../../Transactions/annual-cashflow/months/Utils/Editors/type-type/type-type.component';
import {
  DetailsTypeComponent,
} from '../../Transactions/annual-cashflow/months/Utils/Editors/details-type/details-type.component';
import {
  NumberTypeComponent,
} from '../../Transactions/annual-cashflow/months/Utils/Editors/income-type/number-type.component';
import {
  SelectAccountsComponent,
} from '../../Transactions/annual-cashflow/months/Utils/Editors/select-accounts/select-accounts.component';
import { TransactionService } from '../../../service/Transaction/transaction.service';
import { NbToastrService } from '@nebular/theme';
import { Transaction } from 'app/modules/financial-management/models/Transaction';

@Component({
  selector: 'ngx-icome-statement-list',
  templateUrl: './icome-statement-list.component.html',
  styleUrls: ['./icome-statement-list.component.scss']
})
export class IcomeStatementListComponent implements OnInit {

  transactionData: Transaction[] = [];

  incomeStatementSettings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },

    hideSubHeader: false,

    pager: {
      display: false, // Désactiver le pager
    },
    columns: {
      date: {
        title: 'Date',
        type: 'Date',
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          return new DatePipe('en-EN').transform(raw, 'mediumDate');
        },
        editor: { type: 'custom', component: DateTypeComponent },
      },
      type: {
        title: 'Type',
        type: 'string',
        editor: {
          type: 'custom', component: TypeTypeComponent,
        },
      },
      details: {
        title: 'Details',
        type: 'string',
        editor: { type: 'custom', component: DetailsTypeComponent },
      },
      direction: {
        title: 'Direction',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.type === 'Total Revenue' || row.type === 'Total Expense') {
            return `<h5><span class="badge badge-light"> ${row.type}</span></h5>`;
          } else if (row.type === 'Net Income') {
            return `<h5><span class="badge badge-secondary"> Net Income</span></h5>`;
          } else if (row.balance < 0) {
            return `<h5><span class="badge badge-danger"> Expense</span></h5>`;
          } else if (row.balance > 0) {
            return `<h5><span class="badge badge-success"> Income</span></h5>`;
          }
        },
      },
      balance: {
        title: 'Balance',
        type: 'number',
        addable: true,
        editor: { type: 'custom', component: NumberTypeComponent },
      },
      
    },
  };

  constructor(private ts: TransactionService, private toastrService: NbToastrService) {}

  ngOnInit(): void {
    this.getAllTransactions();
  }

  getAllTransactions() {
    this.ts.getAllTransactions().subscribe(
      (data: Transaction[]) => {
        console.log(data);
        return this.transactionData = data;
      },
      (err) => {
        return err;
      },
    );
  }
}
