import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Asset } from 'app/modules/financial-management/models/Asset';
import { AssetService } from 'app/modules/financial-management/service/AssetService/asset-service.service';
import { ExportService } from 'app/shared/exports/export.service';
import { NbDialogService } from '@nebular/theme'; // Ajouter ceci pour les dialogues

@Component({
  selector: 'ngx-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss']
})
export class AssetsListComponent implements OnInit{
  asset: Asset[];
    isLoading = true;

    assetsData: Asset[] = [];
    settings = {
      hideSubHeader: false,
      actions: {
          add: false,
          edit: false,
          delete: false,
          custom: [
              {
                  name: 'view',
                  title: '<i class="fas fa-eye small mt-2"></i>',
              },
             
          ],
      },
      columns: {
          name: {
              title: 'Name',
              type: 'string',
          },
          initialValue: {
              title: 'Initial Value',
              type: 'number',
          },
          currentValue: {
              title: 'Current Value',
              type: 'number',
          },
          account: {
              title: 'Account',
              valuePrepareFunction: (cell: any, row: any) => {
                  return row.account?.name;
              }
          }
      },
      pager: {
        display: true,
        perPage: 10,
      },
  };
  


    constructor(
      private router: Router,
      private es: ExportService,
      private assetService: AssetService,
      private dialogService: NbDialogService // Ajouter ceci pour les dialogues

  ) { }


  ngOnInit(): void {
    this.getAllAssets();
}

getAllAssets() {
  this.assetService.getAllAssets().subscribe((data: Asset[]) => {
    this.assetsData = data.filter(p => !p.archived);
  }, (err) => {
    console.error(err);
  });
}


    exportExcel() {
      const title = 'Assets list';
      const data = this.assetsData.map(p => ({
          'name': p.name,
          'initialValue': p.initialValue,
          'currentValue': p.currentValue,
          'purchaseDate': p.purchaseDate,
          'depreciationRate': p.depreciationRate,
          'depreciationDate': p.depreciationDate,
          'account': p.account.name,
          

      }));
      this.es.exportExcel(title, data);
  }



  onCustom(event): void {
    const selectedAsset: Asset = event.data;
      console.log('Selected Account ID:', selectedAsset.id);
  this.router.navigate(['/finance/updateAsset', selectedAsset.id]); }

  
 
//Méthode pour ouvrir une boîte de dialogue pour entrer le taux de dépréciation
openDepreciationDialog(asset: Asset): void {
  const depreciationRate = prompt('Enter depreciation rate (e.g., 0.05 for 5%):');
  if (depreciationRate) {
      this.applyDepreciation(asset.id, parseFloat(depreciationRate));
  }
}

// Méthode pour appliquer la dépréciation
applyDepreciation(id: string, depreciationRate: number): void {
  this.assetService.applyDepreciation(id, depreciationRate).subscribe(
    (updatedAsset: Asset) => {
      const index = this.assetsData.findIndex(a => a.id === updatedAsset.id);
      if (index !== -1) {
        this.assetsData[index] = updatedAsset;
        this.assetsData = [...this.assetsData]; // Mise à jour de la vue forcée
      }
    },
    (err) => {
      console.error('Error applying depreciation:', err);
    }
  );
}

}