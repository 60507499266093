import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      &copy; Manajero,
      <b><a href="https://www.ipactconsult.com" target="_blank"> IPACT</a></b>
      {{year}}. All rights reserved..
    </span>
    <div class="socials">
<!--      <a href="https://gitlab.com/m7532/manazello-admin" target="_blank" class="ion ion-social-github"></a>-->
<!--      <a href="#" target="_blank" class="ion ion-social-facebook"></a>-->
<!--      <a href="#" target="_blank" class="ion ion-social-twitter"></a>-->
<!--      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>-->
    </div>
  `,
})
export class FooterComponent {
  year: number = new Date().getFullYear();
}